<template>
    <div>
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="listData"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <div class="btn-group ml-5">
            <vs-button
              size="small"
              color="success"
              type="border"
              @click="handleExport('excel')"
              >Excel</vs-button
            >
          </div>
        </template>
        <template slot="thead">
          <!-- <vs-th></vs-th> -->
          <vs-th sort-key="u">u</vs-th>
          <vs-th sort-key="so_creation_date">SO Creation Date</vs-th>
          <vs-th sort-key="ship_to_party_name">Ship-To Party Name</vs-th>
          <vs-th sort-key="sales_code_desc">Sales Code Desc.</vs-th>
          <vs-th sort-key="actual_gi_date">Actual GI Date</vs-th>
          <vs-th sort-key="asm_code">ASM Code</vs-th>
          <vs-th sort-key="asm_desc">ASM Desc</vs-th>
          <vs-th sort-key="batch_no">Batch No.</vs-th>
          <vs-th sort-key="bill_amount">Bill Amount</vs-th>
          <vs-th sort-key="bill_posting_status">Bill Posting Status</vs-th>
          <vs-th sort-key="bill_qty">Bill Qty</vs-th>
          <vs-th sort-key="bill_unit">Bill Unit</vs-th>
          <vs-th sort-key="bill_vol_unit">Bill Voll Unit</vs-th>
          <vs-th sort-key="bill_volume">Bill Volume</vs-th>
          <vs-th sort-key="bill_creation_date">Bill Creation Date</vs-th>
          <vs-th sort-key="bill_item">Bill Item</vs-th>
          <vs-th sort-key="billing_date">Billing Date</vs-th>
          <vs-th sort-key="billing_no">Billing No</vs-th>
          <vs-th sort-key="cust_grp_1_cd">Cust. Grp. 1 Cd</vs-th>
          <vs-th sort-key="cust_grp_1_desc">Cust. Grp. 1 Desc</vs-th>
          <vs-th sort-key="cust_grp_2_cd">Cust. Grp. 2 Cd</vs-th>
          <vs-th sort-key="cust_grp_2_desc">Cust. Grp. 2 Desc</vs-th>
          <vs-th sort-key="cust_grp_3_cd">Cust. Grp. 3 Cd</vs-th>
          <vs-th sort-key="cust_grp_3_desc">Cust. Grp. 3 Desc</vs-th>
          <vs-th sort-key="cust_grp_4_cd">Cust. Grp. 4 Cd</vs-th>
          <vs-th sort-key="cust_grp_4_desc">Cust. Grp. 4 Desc</vs-th>
          <vs-th sort-key="cust_grp_cd">Cust. Grp. Cd</vs-th>
          <vs-th sort-key="customer_group">Customer Group</vs-th>
          <vs-th sort-key="deliv_status">Deliv. Status</vs-th>
          <vs-th sort-key="dist_chl">Dist. Chl</vs-th>
          <vs-th sort-key="div">Div</vs-th>
          <vs-th sort-key="div_desc">Div. Desc</vs-th>
          <vs-th sort-key="do_amount">DO Amount</vs-th>
          <vs-th sort-key="do_creation_date">DO Creation Date</vs-th>
          <vs-th sort-key="do_item">DO Item</vs-th>
          <vs-th sort-key="do_number">DO Number</vs-th>
          <vs-th sort-key="do_qty">DO Qty</vs-th>
          <vs-th sort-key="do_unit">DO Unit</vs-th>
          <vs-th sort-key="gi_amt">GI Amt</vs-th>
          <vs-th sort-key="gi_cur">GI Cur</vs-th>
          <vs-th sort-key="gi_no">GI No</vs-th>
          <vs-th sort-key="gi_qty">GI Qty</vs-th>
          <vs-th sort-key="gi_uom">GI UOM</vs-th>
          <vs-th sort-key="gi_vol_un">GI Vol Un</vs-th>
          <vs-th sort-key="gi_volume">GI Volume</vs-th>
          <vs-th sort-key="inco">Inco</vs-th>
          <vs-th sort-key="item_category">Item Category</vs-th>
          <vs-th sort-key="mat_gr_1_desc">Mat. Gr1 Desc</vs-th>
          <vs-th sort-key="mat_gr_2_desc">Mat. Gr2 Desc</vs-th>
          <vs-th sort-key="mat_gr_3_desc">Mat. Gr3 Desc</vs-th>
          <vs-th sort-key="mat_gr_4_desc">Mat. Gr4 Desc</vs-th>
          <vs-th sort-key="mat_gr_5_desc">Mat. Gr5 Desc</vs-th>
          <vs-th sort-key="mat_grp_1">Mat. Grp1</vs-th>
          <vs-th sort-key="mat_grp_2">Mat. Grp2</vs-th>
          <vs-th sort-key="mat_grp_3">Mat. Grp3</vs-th>
          <vs-th sort-key="mat_grp_4">Mat. Grp4</vs-th>
          <vs-th sort-key="mat_grp_5">Mat. Grp5</vs-th>
          <vs-th sort-key="material_code">Material Code</vs-th>
          <vs-th sort-key="material_desc">Material Desc</vs-th>
          <vs-th sort-key="order_type">Order Type</vs-th>
          <vs-th sort-key="order_type_desc">Order Type Desc</vs-th>
          <vs-th sort-key="plan_gi_date">Plan GI Date</vs-th>
          <vs-th sort-key="plant">Plant</vs-th>
          <vs-th sort-key="po_date">PO Date</vs-th>
          <vs-th sort-key="pod_date">POD Date</vs-th>
          <vs-th sort-key="pod_verification_date">POD Verification Date</vs-th>
          <vs-th sort-key="purchase_order_no">Purchase Order No</vs-th>
          <vs-th sort-key="reject">Reject</vs-th>
          <vs-th sort-key="route">Route</vs-th>
          <vs-th sort-key="route_desc">Route Desc</vs-th>
          <vs-th sort-key="rsm_code">RSM Code</vs-th>
          <vs-th sort-key="rsm_desc">RSM Desc</vs-th>
          <vs-th sort-key="sal_off">Sal. Off.</vs-th>
          <vs-th sort-key="sal_off_desc">Sal. Off. Desc.</vs-th>
          <vs-th sort-key="sales_code">Sales Code</vs-th>
          <vs-th sort-key="ship_to_party">Ship to Party</vs-th>
          <vs-th sort-key="ship_to_party_name_2">Ship to Party Name 2</vs-th>
          <vs-th sort-key="ship_point">Ship Point</vs-th>
          <vs-th sort-key="so_amount">SO Amount</vs-th>
          <vs-th sort-key="so_created_by">SO Created By</vs-th>
          <vs-th sort-key="so_creation_time">SO Creation Time</vs-th>
          <vs-th sort-key="so_currency">SO Currency</vs-th>
          <vs-th sort-key="so_doc_date">SO Doc Date</vs-th>
          <vs-th sort-key="so_item">SO Item</vs-th>
          <vs-th sort-key="so_qty">SO Qty</vs-th>
          <vs-th sort-key="so_unit">SO Unit</vs-th>
          <vs-th sort-key="so_unit_price">SO Unit Price</vs-th>
          <vs-th sort-key="so_volume">SO Volume</vs-th>
          <vs-th sort-key="so_volume_unit">SO Volume Unit</vs-th>
          <vs-th sort-key="sold_to_party">Sold to Party</vs-th>
          <vs-th sort-key="sold_to_party_name">Sold to Party Name</vs-th>
          <vs-th sort-key="sold_to_party_name_2">Sold to Party Name 2</vs-th>
          <vs-th sort-key="street">Street</vs-th>
          <vs-th sort-key="kelurahan">Kelurahan</vs-th>
          <vs-th sort-key="kecamatan">Kecamatan</vs-th>
          <vs-th sort-key="kota_kabupaten">Kota/Kabupaten</vs-th>
          <vs-th sort-key="jarak">Jarak</vs-th>
          <vs-th sort-key="qty_gi_in">Qty GI in</vs-th>
          <vs-th sort-key="qty_do_in">Qty DO in</vs-th>
        </template>
  
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td> </vs-td> -->
            <vs-td :data="data[indextr].u">
              {{ data[indextr].u }}
            </vs-td>
            <vs-td :data="data[indextr].so_creation_date">
              {{ dateFormat(data[indextr].so_creation_date) }}
            </vs-td>
            <vs-td :data="data[indextr].ship_to_party_name">
              {{ data[indextr].ship_to_party_name }}
            </vs-td>
            <vs-td :data="data[indextr].sales_code_desc">
              {{ data[indextr].sales_code_desc }}
            </vs-td>
            <vs-td :data="data[indextr].actual_gi_date">
              {{ data[indextr].actual_gi_date }}
            </vs-td>
            <vs-td :data="data[indextr].asm_code">
              {{ data[indextr].asm_code }}
            </vs-td>
            <vs-td :data="data[indextr].asm_desc">
              {{ (data[indextr].asm_desc) }}
            </vs-td>
            <vs-td :data="data[indextr].batch_no">
              {{ (data[indextr].batch_no) }}
            </vs-td>
            <vs-td :data="data[indextr].bill_amount">
              {{ formatPrice(data[indextr].bill_amount) }}
            </vs-td>
            <vs-td :data="data[indextr].bill_posting_status">
              {{ (data[indextr].bill_posting_status) }}
            </vs-td>
            <vs-td :data="data[indextr].bill_qty">
              {{ (data[indextr].bill_qty) }}
            </vs-td>
            <vs-td :data="data[indextr].bill_unit">
              {{ data[indextr].bill_unit }}
            </vs-td>
            <vs-td :data="data[indextr].bill_vol_unit">
              {{ data[indextr].bill_vol_unit }}
            </vs-td>
            <vs-td :data="data[indextr].bill_volume">
              {{ data[indextr].bill_volume }}
            </vs-td>
            <vs-td :data="data[indextr].bill_creation_date">
              {{ dateFormat(data[indextr].bill_creation_date) }}
            </vs-td>
            <vs-td :data="data[indextr].bill_item">
              {{ data[indextr].bill_item }}
            </vs-td>
            <vs-td :data="data[indextr].billing_date">
              {{ dateFormat(data[indextr].billing_date) }}
            </vs-td>
            <vs-td :data="data[indextr].billing_no">
              {{ data[indextr].billing_no }}
            </vs-td>
            <vs-td :data="data[indextr].cust_grp_1_cd">
              {{ data[indextr].cust_grp_1_cd }}
            </vs-td>
            <vs-td :data="data[indextr].cust_grp_1_desc">
              {{ data[indextr].cust_grp_1_desc }}
            </vs-td>
            <vs-td :data="data[indextr].cust_grp_2_cd">
              {{ data[indextr].cust_grp_2_cd }}
            </vs-td>
            <vs-td :data="data[indextr].cust_grp_2_desc">
              {{ data[indextr].cust_grp_2_desc }}
            </vs-td>
            <vs-td :data="data[indextr].cust_grp_3_cd">
              {{ data[indextr].cust_grp_3_cd }}
            </vs-td>
            <vs-td :data="data[indextr].cust_grp_3_desc">
              {{ data[indextr].cust_grp_3_desc }}
            </vs-td>
            <vs-td :data="data[indextr].cust_grp_4_cd">
              {{ data[indextr].cust_grp_4_cd }}
            </vs-td>
            <vs-td :data="data[indextr].cust_grp_4_desc">
              {{ data[indextr].cust_grp_4_desc }}
            </vs-td>
            <vs-td :data="data[indextr].cust_grp_cd">
              {{ data[indextr].cust_grp_cd }}
            </vs-td>
            <vs-td :data="data[indextr].customer_group">
              {{ data[indextr].customer_group }}
            </vs-td>
            <vs-td :data="data[indextr].deliv_status">
              {{ data[indextr].deliv_status }}
            </vs-td>
            <vs-td :data="data[indextr].dist_chl">
              {{ data[indextr].dist_chl }}
            </vs-td>
            <vs-td :data="data[indextr].div">
              {{ data[indextr].div }}
            </vs-td>
            <vs-td :data="data[indextr].div_desc">
              {{ data[indextr].div_desc }}
            </vs-td>
            <vs-td :data="data[indextr].do_amount">
              {{ data[indextr].do_amount }}
            </vs-td>
            <vs-td :data="data[indextr].do_creation_date">
              {{ dateFormat(data[indextr].do_creation_date) }}
            </vs-td>
            <vs-td :data="data[indextr].do_item">
              {{ data[indextr].do_item }}
            </vs-td>
            <vs-td :data="data[indextr].do_number">
              {{ data[indextr].do_item }}
            </vs-td>
            <vs-td :data="data[indextr].do_qty">
              {{ data[indextr].do_qty }}
            </vs-td>
            <vs-td :data="data[indextr].do_unit">
              {{ data[indextr].do_unit }}
            </vs-td>
            <vs-td :data="data[indextr].gi_amt">
              {{ data[indextr].gi_amt }}
            </vs-td>
            <vs-td :data="data[indextr].gi_cur">
              {{ data[indextr].gi_cur }}
            </vs-td>
            <vs-td :data="data[indextr].gi_no">
              {{ data[indextr].gi_no }}
            </vs-td>
            <vs-td :data="data[indextr].gi_qty">
              {{ data[indextr].gi_qty }}
            </vs-td>
            <vs-td :data="data[indextr].gi_uom">
              {{ data[indextr].gi_uom }}
            </vs-td>
            <vs-td :data="data[indextr].gi_vol_un">
              {{ data[indextr].gi_vol_un }}
            </vs-td>
            <vs-td :data="data[indextr].gi_volume">
              {{ data[indextr].gi_volume }}
            </vs-td>
            <vs-td :data="data[indextr].inco">
              {{ data[indextr].inco }}
            </vs-td>
            <vs-td :data="data[indextr].item_category">
              {{ data[indextr].item_category }}
            </vs-td>
            <vs-td :data="data[indextr].mat_gr_1_desc">
              {{ data[indextr].mat_gr_1_desc }}
            </vs-td>
            <vs-td :data="data[indextr].mat_gr_2_desc">
              {{ data[indextr].mat_gr_2_desc }}
            </vs-td>
            <vs-td :data="data[indextr].mat_gr_3_desc">
              {{ data[indextr].mat_gr_3_desc }}
            </vs-td>
            <vs-td :data="data[indextr].mat_gr_4_desc">
              {{ data[indextr].mat_gr_4_desc }}
            </vs-td>
            <vs-td :data="data[indextr].mat_gr_5_desc">
              {{ data[indextr].mat_gr_5_desc }}
            </vs-td>
            <vs-td :data="data[indextr].mat_grp_1">
              {{ data[indextr].mat_grp_1 }}
            </vs-td>
            <vs-td :data="data[indextr].mat_grp_2">
              {{ data[indextr].mat_grp_2 }}
            </vs-td>
            <vs-td :data="data[indextr].mat_grp_3">
              {{ data[indextr].mat_grp_3 }}
            </vs-td>
            <vs-td :data="data[indextr].mat_grp_4">
              {{ data[indextr].mat_grp_4 }}
            </vs-td>
            <vs-td :data="data[indextr].mat_grp_5">
              {{ data[indextr].mat_grp_5 }}
            </vs-td>
            <vs-td :data="data[indextr].material_code">
              {{ data[indextr].material_code }}
            </vs-td>
            <vs-td :data="data[indextr].material_desc">
              {{ data[indextr].material_desc }}
            </vs-td>
            <vs-td :data="data[indextr].order_type">
              {{ data[indextr].order_type }}
            </vs-td>
            <vs-td :data="data[indextr].order_type_desc">
              {{ data[indextr].order_type_desc }}
            </vs-td>
            <vs-td :data="data[indextr].plan_gi_date">
              {{ dateFormat(data[indextr].plan_gi_date) }}
            </vs-td>
            <vs-td :data="data[indextr].plant">
              {{ data[indextr].plant }}
            </vs-td>
            <vs-td :data="data[indextr].po_date">
              {{ dateFormat(data[indextr].po_date) }}
            </vs-td>
            <vs-td :data="data[indextr].pod_date">
              {{ dateFormat(data[indextr].pod_date) }}
            </vs-td>
            <vs-td :data="data[indextr].pod_verification_date">
              {{ dateFormat(data[indextr].pod_verification_date) }}
            </vs-td>
            <vs-td :data="data[indextr].purchase_order_no">
              {{ data[indextr].purchase_order_no }}
            </vs-td>
            <vs-td :data="data[indextr].reject">
              {{ data[indextr].reject }}
            </vs-td>
            <vs-td :data="data[indextr].route">
              {{ data[indextr].route }}
            </vs-td>
            <vs-td :data="data[indextr].route_desc">
              {{ data[indextr].route_desc }}
            </vs-td>
            <vs-td :data="data[indextr].rsm_code">
              {{ data[indextr].rsm_code }}
            </vs-td>
            <vs-td :data="data[indextr].rsm_desc">
              {{ data[indextr].rsm_desc }}
            </vs-td>
            <vs-td :data="data[indextr].sal_off">
              {{ data[indextr].sal_off }}
            </vs-td>
            <vs-td :data="data[indextr].sal_off_desc">
              {{ data[indextr].sal_off_desc }}
            </vs-td>
            <vs-td :data="data[indextr].sales_code">
              {{ data[indextr].sales_code }}
            </vs-td>
            <vs-td :data="data[indextr].ship_to_party">
              {{ data[indextr].ship_to_party }}
            </vs-td>
            <vs-td :data="data[indextr].ship_to_party_name_2">
              {{ data[indextr].ship_to_party_name_2 }}
            </vs-td>
            <vs-td :data="data[indextr].ship_point">
              {{ data[indextr].ship_point }}
            </vs-td>
            <vs-td :data="data[indextr].so_amount">
              {{ data[indextr].so_amount }}
            </vs-td>
            <vs-td :data="data[indextr].so_created_by">
              {{ data[indextr].so_created_by }}
            </vs-td>
            <vs-td :data="data[indextr].so_creation_time">
              {{ data[indextr].so_creation_time }}
            </vs-td>
            <vs-td :data="data[indextr].so_currency">
              {{ data[indextr].so_currency }}
            </vs-td>
            <vs-td :data="data[indextr].so_doc_date">
              {{ dateFormat(data[indextr].so_doc_date) }}
            </vs-td>
            <vs-td :data="data[indextr].so_item">
              {{ data[indextr].so_item }}
            </vs-td>
            <vs-td :data="data[indextr].so_qty">
              {{ data[indextr].so_qty }}
            </vs-td>
            <vs-td :data="data[indextr].so_unit">
              {{ data[indextr].so_unit }}
            </vs-td>
            <vs-td :data="data[indextr].so_unit_price">
              {{ data[indextr].so_unit_price }}
            </vs-td>
            <vs-td :data="data[indextr].so_volume">
              {{ data[indextr].so_volume }}
            </vs-td>
            <vs-td :data="data[indextr].so_volume_unit">
              {{ data[indextr].so_volume_unit }}
            </vs-td>
            <vs-td :data="data[indextr].sold_to_party">
              {{ data[indextr].sold_to_party }}
            </vs-td>
            <vs-td :data="data[indextr].sold_to_party_name">
              {{ data[indextr].sold_to_party_name }}
            </vs-td>
            <vs-td :data="data[indextr].sold_to_party_name_2">
              {{ data[indextr].sold_to_party_name_2 }}
            </vs-td>
            <vs-td :data="data[indextr].street">
              {{ data[indextr].street }}
            </vs-td>
            <vs-td :data="data[indextr].kelurahan">
              {{ data[indextr].kelurahan }}
            </vs-td>
            <vs-td :data="data[indextr].kecamatan">
              {{ data[indextr].kecamatan }}
            </vs-td>
            <vs-td :data="data[indextr].kota_kabupaten">
              {{ data[indextr].kota_kabupaten }}
            </vs-td>
            <vs-td :data="data[indextr].jarak">
              {{ data[indextr].jarak }}
            </vs-td>
            <vs-td :data="data[indextr].qty_gi_in">
              {{ data[indextr].qty_gi_in }}
            </vs-td>
            <vs-td :data="data[indextr].qty_do_in">
              {{ data[indextr].qty_do_in }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="totalPage"
        v-model="setPage"
      />
    </div>
  </template>
  <script>
  import moment from "moment";
  import { mapState, mapActions } from "vuex/dist/vuex.common.js";
  export default {
    components: {},
    props: {
      baseUrl: {
        type: String,
      },
      startPostingDate: {
        type: Date,
      },
      endPostingDate: {
        type: Date,
      },
      startDocDate: {
        type: Date,
      },
      endDocDate: {
        type: Date,
      },
      startDueDate: {
        type: Date,
      },
      endDueDate: {
        type: Date,
      },
      openKeyDate: {
        type: Date,
      },
      territoryIDs: {
        type: Array,
      },
      territoryNames: {
        type: Array,
      },
      draw: {
        type: Number,
      },
    },
    data() {
      return {
        deleteId: null,
        table: this.tableDefaultState(),
      };
    },
    methods: {
      ...mapActions({
        getSalesOrderReport: 'reportSalesOrder/getSalesOrderReport',
        generateSalesOrderReport: 'reportSalesOrder/generateSalesOrderReport',
      }),
      tableDefaultState() {
        return {
          data: [],
          length: 10,
          page: 1,
          search: "",
          order: "id",
          sort: "desc",
          total: 0,
          totalPage: 0,
          totalSearch: 0,
          limits: [10, 25, 50, 100, "All"],
          start: 1,
          end: 0,
        };
      },
      handleSearch(searching) {
        this.table.search = searching;
        this.table.page = 1;
        this.getData();
      },
      handleChangePage(page) {
        this.table.page = page;
        this.getData();
      },
      handleSort(key, active) {
        this.table.order = key;
        this.table.sort = active;
        this.getData();
      },
      handleChangelength(val) {
        this.table.length = val == "All" ? this.table.total : val;
        this.table.page = 1;
        this.getData();
      },
      handleExport() {
            this.$vs.loading();
        this.generateSalesOrderReport(
        {
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
        }
      ).then(() => {
        this.$vs.loading.close();
      })
      },
      getData() {
        if (this.draw > 0) {
          this.$vs.loading();
          this.getSalesOrderReport({
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
          })
            .then((resp) => {
              this.$vs.loading.close();
            });
        }
      },
      setStartEnd() {
        let valStart =
          this.table.length * this.table.page - this.table.length + 1;
  
        if (valStart > this.table.total) {
          valStart = 1;
        }
        if (this.table.total == 0) {
          valStart = 0;
        }
        let valEnd = this.table.length * this.table.page;
  
        if (valEnd > this.table.total) {
          valEnd = this.table.total;
        }
  
        if (
          this.table.totalSearch < this.table.total &&
          this.table.search != ""
        ) {
          valEnd = this.table.totalSearch;
        }
  
        this.table.start = valStart;
        this.table.end = valEnd;
      },
      dateFormat(val) {
        return moment(val).format("DD MMM YYYY");
      },
      formatPrice(val) {
        if (isNaN(val)) {
          val = 0;
        }
        val = (val / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      format(head, value) {
        if (typeof head["format"] === "function") {
          var f = head["format"];
          return f(value);
        }
        return value;
      },
    },
    watch: {
      draw() {
        this.getData();
      },
      listData(val) {
            this.table.start = ((this.table.page - 1) * this.table.length) + 1
            this.table.end = ((this.table.page - 1) * this.table.length) + val.length
        },
        generateReport(val) {
            if (val && val.status === 'OK') {
                this.$vs.notify({
                    color: "success",
                    title: "Processing",
                    text: val.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: val.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
             }
        },
        totalRecord(val) {
            this.table.total = val
        },
    },
    computed: {
      ...mapState({
        listData: (state) => state.reportSalesOrder.tableData,
        totalPage: (state) => state.reportSalesOrder.total,
        totalRecord: (state) => state.reportSalesOrder.total_record,
        generateReport: (state) => state.reportSalesOrder.generateReport,
      }),
      setPage: {
        get() {
          return 1;
        },
        set(val) {
          this.handleChangePage(val);
        },
      },
    },
  };
  </script>